.App {
  background-color: #dce2f1/* #f3f6fc */;
}

.fixed_top_container {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
  opacity: .9;
}

.main_container{
  margin-top: 40px;
}

.banner-container {
  display: block;
  grid-template-columns: 1fr 1fr;
  color: white;
  margin: auto;
}

.about-me-container {
  padding: 20px;
  background-color: #0B102D;
  color: white;
}

.about-me-title {
  text-align: left;
}

.about-me-text {
  text-align: left;
  font-size: larger;

}
.intro-container {
  display: flex;
  flex-wrap: wrap;
  align-content:space-around;
  padding: 20px;
  background-image: url(banner.webp);
  background-position: center;
  background-size: cover;
  height: 350px;
}

.intro-text {
  color: white;
  opacity: .8;
  background-color: black;
  padding: 0px 20px 0px 20px;
  text-align: left;
  width: fit-content;
  border-radius: 5px;
  font-size: larger;
  height: fit-content;
  
}


.project-card-list-container {
  display: flex;
  justify-content: center;
}

.projects {
  margin: auto;
  margin-top: 50px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.projects-heading {
  line-height: 1em;
  padding-bottom: 20px;
  text-align: center;
}

.project-card-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  justify-items:center;
  width: 100%;
  text-align: center;
}

.project-card {
  width: 45ch;
  height: 400px;
  position: relative;
  margin-bottom: 50px;
  background-color: white;
  padding-top: 1px;
}

.project-card-image {
  padding: 0 2px;  
}

.project-card-footer-text {
  display: grid;
  grid-template-columns: 1fr 1fr;
  text-align: left;
  padding-left: 10px;
}

.project-notes-container {
  padding-left: 10px;
  padding-bottom: 10px;
  text-align: left;

}

.project-button-container{  
  position: absolute;
  bottom: 0;
  width: 100%;
}

.project-button {
  width: 50%;
  height: 3.5em;
  text-transform: uppercase;
  background-color: #0B102D;
  color: white;
  border: 1px solid white;
  font-weight: 500;
  cursor: pointer;
  border-radius: 5px;

}

.skills-heading {
  text-align: center;
  margin-top: 0;
}

.skills-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
/*   border-top: 1px solid lightgrey;
  border-bottom: 1px solid lightgrey; */
  padding-top: 10px;
  padding-bottom: 10px;
  color: #0A1A39;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px; */
  justify-content: space-evenly;
  margin-bottom: 50px;
  background-color: #8c9fc4;
}

.skills-grid p {
  background-color: #0B102D;
  color: white;
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 25px;
  margin-top: 8px;
  margin-bottom: 8px;
  text-align: center;
}

.contact-me {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
}

.contact-me a {
  color: white;
  width: 100%;
  text-align: center;
  text-decoration: none;
}

.certifications {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  width: 50%;
  height: 40px;
  border-left: solid .5px #dce2f1;
}


.certificationsList {
  padding: 5px;
  background-color: #dce2f1
}

.cert-card {
  padding: 5px;
  background-color: white;
  margin-bottom: 20px;
}

.cert-course {
  background-color: #8c9fc4;
  padding: 10px;
}

.individual-course {
  cursor: pointer;
  font-weight: 300;
  background-color: #0B102D;
  width:fit-content;
  margin: 0 auto;
  margin-bottom: 20px;
  margin-top: 20px;
  border-radius: 15px;
  padding: 5px 15px;
  color: white
}

.navBar {
  display: flex;
  align-items: center;
  justify-content:space-evenly;
  background-color: #0B102D;
  height: 40px;
  max-width: 1400px;
}

.signature {
  color: white;
  cursor: pointer;
}

.footer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0B102D;
  position: fixed;
  bottom: 0;
  width: 100%;
  max-width: 1400px;
}

@media only screen and (max-width: 600px) {
  .skills-grid {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .skills-grid i {
    margin-top: 10px;
  }

  .project-card {
    width: 44ch;
  }
  .intro-text {
    width: 60%;
    text-align: left;
    padding-left: 10px;
  }
  
}
